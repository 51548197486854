import { useEffect, useRef, useState } from "react";
import "./AIDatePicker.css";
import { AiFillCalendar } from "react-icons/ai";
import { Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import useClickOutside from "../../../hook/useClickOutside";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface PropData {
  date: any;
  selectDate: any;
  minDate?: any;
  maxDate?: any;
  showTime?: boolean;
  displayFormat?: string;
  isShowTop?: boolean;
  addMb?: string;
  isInChatBot?: boolean;
}

const AIDatePicker = (props: PropData) => {
  const [showPicker, setShowPicker] = useState(false);
  const onChangeEvent = useRef(false);
  const [disFormat, setDisFormat] = useState<string>("MM-DD-yyyy");

  const getDateChangeEvent = (ranges: any) => {
    onChangeEvent.current = true;
    props.selectDate(ranges);
    setShowPicker(false);
  };
  const getPosistion = () => {
    onChangeEvent.current
      ? (onChangeEvent.current = false)
      : setShowPicker(true);
  };

  let domNode = useClickOutside(() => {
    setShowPicker(false);
  }, this);

  useEffect(() => {
    props.displayFormat && setDisFormat(props.displayFormat);
  }, [props.displayFormat]);

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div id="pickerDiv" ref={domNode}>
      <InputGroup onClick={() => getPosistion()} className={props.addMb === "ab" ? " mClass" : "mb-3 mClass"}>
        <Form.Control
          aria-describedby="basic-addon2"
          value={moment(props.date).format(disFormat)}
          onChange={() => { }}
        />
        <InputGroup.Text id="basic-addon2" className="mClass">
          <AiFillCalendar size={18} className="icon" />
        </InputGroup.Text>
        {showPicker ? (
          <div className={"posClass " + (props.isShowTop ? "showTop" : "") + (props?.isInChatBot ? " align-center" : "")}>
            <DatePicker
              selected={props.date}
              onChange={(date) => getDateChangeEvent(date)}
              showTimeSelect={props.showTime}
              timeIntervals={5}
              inline
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={props.minDate}
              maxDate={props.maxDate}
              filterTime={filterPassedTime}

            />
          </div>
        ) : (
          ""
        )}
      </InputGroup>
    </div>
  );
};

export default AIDatePicker;
