import {
    Offcanvas,
    Card,
    Col,
    Row,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import WebService from "../../../Services/WebService";
import Avart1 from "../../../assets/images/avtar-male.png"
import Avart2 from "../../../assets/images/default-avatar.jpg"
import TableLoader from "../../Common/TableLoader/TableLoader";
import HelperService from "../../../Services/HelperService";
import moment from "moment";
import NoImage from "../../../assets/images/noImage.png"
import NoDataFound from "../../../assets/images/empty_item.svg";
import { useSelector } from "react-redux";
import { TooltipCustom } from "../../Common/Tooltip/Tooltip";
import { BiDownload } from "react-icons/bi";

interface PropData {
    smsConversationData?: any;
    parentCallback?: any;
    selectedTab?: any;

}

const MessageConversationBlade = (props: PropData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const [loader, setLoader] = useState(false);
    const [chatList, setChatList] = useState<any>([]);
    const {
        formState: { errors },
        watch,
    } = useForm();

    useEffect(() => {
        getChatList();
    }, []);
    const getChatList = () => {
        setLoader(true);
        var obj = {
            "from_number": props.smsConversationData.fromNumber,
            "to_number": props.smsConversationData.toNumber,
            "keyword": "",
            "page": "1",
            "type": props.selectedTab
        }
        var qurey = new URLSearchParams(obj).toString();

        return WebService.getAPI({
            action: `whatsapp-conversation?` + qurey,
            body: obj,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                if (res.list.length > 0) {
                    const reversedList: any[] = [];
                    for (let i = res.list.length - 1; i >= 0; i--) {
                        reversedList.push(res.list[i]);
                    }
                    setChatList(reversedList)
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    };

    const handleError = () => { };

    return (
        <>

            <Offcanvas.Body className="px-0 py-3">
                <div className="px-3">
                    {
                        !loader
                            ?
                            chatList.length > 0 ?
                                <div className="chat-box h-auto">
                                    {
                                        chatList?.map((item: any, index: any) => {
                                            return (
                                                item.senderType === "INBOUND"
                                                    ?
                                                    <Row className="mb-3 mx-0">
                                                        <Col lg={8} className="d-flex gap-2 align-items-end">
                                                            <div>
                                                                <img src={Avart2} width={35} height={35} className=" object-fit-cover rounded-circle" alt="avtar" />
                                                            </div>
                                                            <div>

                                                                {
                                                                    item.mediaType === "TEXT_MESSAGE" ?
                                                                        <>
                                                                            <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                <p>
                                                                                    <span dangerouslySetInnerHTML={{ __html: HelperService.getHighlightedContent(item?.message, item?.highlighted) }}></span>
                                                                                </p>
                                                                            </Card>
                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.messageDateTime)
                                                                                .local()
                                                                                .format("MM-DD-YY hh:mm A ")}</p>
                                                                        </>
                                                                        : item.mediaType === "IMAGE" ?
                                                                            <>
                                                                                <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                    <img
                                                                                        onClick={() => window.open(item?.mediaPath)}
                                                                                        className='rounded-3 img-fluid object-fit-contain cursor-pointer'
                                                                                        style={{ maxHeight: "200px" }}
                                                                                        src={item?.mediaPath}
                                                                                    />
                                                                                </Card>
                                                                                <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                    .local()
                                                                                    .format("MM-DD-YY hh:mm A ")}</p>
                                                                            </> : item.mediaType === "VIDEO" ?
                                                                                <>
                                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                        <video width="280" height="200" controls>
                                                                                            <source src={item?.mediaPath} type="video/mp4" />
                                                                                        </video>
                                                                                    </Card>
                                                                                    <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                        .local()
                                                                                        .format("MM-DD-YY hh:mm A ")}</p>
                                                                                </>
                                                                                : item.mediaType === "AUDIO" ?
                                                                                    <>
                                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                            <audio className='rounded-3 object-fit-contain cursor-pointer' controls>
                                                                                                <source src={item?.mediaPath} type="video/mp4" />
                                                                                            </audio>
                                                                                        </Card>
                                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                            .local()
                                                                                            .format("MM-DD-YY hh:mm A ")}</p>
                                                                                    </>
                                                                                    : item.mediaType === "FILE" ?
                                                                                        <>
                                                                                            <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                                <Card className="p-4 border-brand h-100  text-center cursor-pointer" onClick={() => window.open(item?.mediaPath)} >
                                                                                                    <BiDownload size={30} className="mb-3 mx-auto icon" />
                                                                                                    {/* <p className="font-500 font-16 mb-0">
                                                                                                        <TooltipCustom
                                                                                                            st={
                                                                                                                <span>{HelperService.truncate(item?.mediaPath, 10)}</span>
                                                                                                            }
                                                                                                            message={item?.fileName}
                                                                                                            position="top"
                                                                                                            id="file"
                                                                                                        />
                                                                                                    </p> */}
                                                                                                </Card>
                                                                                            </Card>
                                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                                .local()
                                                                                                .format("MM-DD-YY hh:mm A ")}</p>
                                                                                        </>
                                                                                        : ""

                                                                }

                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    :
                                                    <>
                                                        <Row className="mb-3 justify-content-end mx-0">
                                                            <Col lg={item?.mediaType !== "TEXT_MESSAGE" && item?.mediaType !== "FILE" ? 8 : 11} className="d-flex gap-2 align-items-end justify-content-end">
                                                                <div>
                                                                    {
                                                                        item.mediaType === "TEXT_MESSAGE" ?
                                                                            <>
                                                                                <Card className="border-0 px-3 py-2 rounded-4 chat-corner-right chat-card">
                                                                                    <p><span dangerouslySetInnerHTML={{ __html: HelperService.getHighlightedContent(item?.message, item?.highlighted) }}></span></p>

                                                                                </Card>
                                                                                <p className="font-10 ms-2 font-light mt-1  text-nowrap">{moment(item.messageDateTime)
                                                                                    .local()
                                                                                    .format("MM-DD-YY hh:mm A ")}</p>
                                                                            </>
                                                                            : item.mediaType === "IMAGE" ?
                                                                                <>
                                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-right chat-card">
                                                                                        <img
                                                                                            onClick={() => window.open(item?.mediaPath)}
                                                                                            className='rounded-3 img-fluid object-fit-contain cursor-pointer'
                                                                                            style={{ maxHeight: "200px" }}
                                                                                            src={item?.mediaPath}
                                                                                        />
                                                                                    </Card>
                                                                                    <p className="font-10 ms-2 font-light mt-1  text-nowrap">{moment(item.dateTime)
                                                                                        .local()
                                                                                        .format("MM-DD-YY hh:mm A ")}</p>
                                                                                </>
                                                                                : item.mediaType === "VIDEO" ?
                                                                                    <>
                                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-right chat-card">
                                                                                            <video width="280" height="200" controls>
                                                                                                <source src={item?.mediaPath} type="video/mp4" />
                                                                                            </video>
                                                                                        </Card>
                                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                            .local()
                                                                                            .format("MM-DD-YY hh:mm A ")}</p>
                                                                                    </>
                                                                                    : item.mediaType === "AUDIO" ?
                                                                                        <>
                                                                                            <Card className="border-0 px-3 py-2 rounded-4 chat-corner-right chat-card">
                                                                                                <audio className='rounded-3 object-fit-contain cursor-pointer' controls>
                                                                                                    <source src={item?.mediaPath} type="video/mp4" />
                                                                                                </audio>
                                                                                            </Card>
                                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                                .local()
                                                                                                .format("MM-DD-YY hh:mm A ")}</p>
                                                                                        </>
                                                                                        : item.mediaType === "FILE" ?
                                                                                            <>
                                                                                                <Card className="border-0 px-3 py-2 rounded-4 chat-corner-right chat-card">
                                                                                                    <Card className="p-4 border-brand h-100  text-center cursor-pointer" onClick={() => window.open(item?.mediaPath)} >
                                                                                                        <BiDownload size={30} className="mb-3 mx-auto icon" />
                                                                                                        <p className="font-500 font-16 mb-0">
                                                                                                            {/* <TooltipCustom
                                                                                                                st={
                                                                                                                    <span>{HelperService.truncate(item?.mediaPath, 10)}</span>
                                                                                                                }
                                                                                                                message={item?.fileName}
                                                                                                                position="top"
                                                                                                                id="file"
                                                                                                            /> */}
                                                                                                        </p>
                                                                                                    </Card>
                                                                                                </Card>
                                                                                                <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">{moment(item.dateTime)
                                                                                                    .local()
                                                                                                    .format("MM-DD-YY hh:mm A ")}</p>
                                                                                            </>
                                                                                            : ""

                                                                    }

                                                                </div>
                                                                <div>
                                                                    <img src={Avart2} width={35} height={35} className=" object-fit-cover rounded-circle" alt="avtar" />
                                                                </div>
                                                            </Col>
                                                        </Row >

                                                    </>
                                            )

                                        })
                                    }

                                </div>
                                :
                                <div className="text-center" style={{ marginTop: "140px" }}>
                                    <img
                                        src={NoDataFound}
                                        width={110}
                                        className="mb-3"
                                        alt={""}
                                    />
                                    <h3 className="text-secondary font-12">
                                        No Conversation Found
                                    </h3>
                                </div>
                            : <TableLoader />
                    }
                </div>

            </Offcanvas.Body >

        </>
    );
};
export default MessageConversationBlade;


