import React, { useState } from "react";
import { Button } from "react-bootstrap";
import WebService from "../../Services/WebService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ReviewName = (props) => {
    const access_token = useSelector((state) => state.aT.aT);
    const t_id = useSelector((state) => state.tI.tenantId);  
    const { actionProvider } = props;
    const [reviewName, setReviewName] = useState("");  
    

    const handleSubmitClick = () => {
        WebService.addLoader("name-submit-btn"); 
        const newArray = props?.payload?.channel_list?.map(item => {
          let social_type = item;
          let account_type = "";
        
          if (item.includes('PERSONAL')) {
            social_type = item.replace('PERSONAL', '');
            account_type = 'PERSONAL';
          } else if (item.includes('BUSINESS')) {
            social_type = item.replace('BUSINESS', '');
            account_type = 'BUSINESS';
          }        
          return {
            socialType: social_type,
            account_type: account_type
          };
        });

        const body = {
          name: reviewName,
          percentage: props?.payload?.sample_rate,
          endDate: props?.payload?.endDate,
          startDate: props?.payload?.startDate,
          // type: "ALL",
          accounts:newArray,
          request_type: "BOT",
          accountType: "",
        };         
        
        return WebService.postAPI({
          action: `review`,
          body: body,
          isShowError: true,
          access_token: access_token,
          t_id: t_id,
        })
          .then((res) => {
            toast.success(res?.message);
            WebService.removeLoader("name-submit-btn");
            actionProvider.handleTimeFrameSelection();
          })
          .catch((error) => {
            WebService.removeLoader("name-submit-btn");
            return error;
          });
          
    };
    

    return (
        <div className='react-chatbot-kit-chat-bot-message'>
            <input
                type="text"
                className="mb-2"
                onChange={(e) => { setReviewName(e.target.value)}}
            >

            </input>
            <Button id="name-submit-btn" className="btn-brand-light mt-2" onClick={(e) => { handleSubmitClick() }}>
                Submit
            </Button>
        </div>
    );
};

export default ReviewName;












