import React, { useState } from "react";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [reviewStep, setReviewStep] = useState(0);

  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");
    addMessageToState(botMessage);
  };

  const handleStartReview = (startDate, endDate, sample_rate, channel_list) => {
    const botMessage = createChatBotMessage(
      "What name would you like to use to save this review?",
      {
        widget: "reviewName",
        withAvatar: true,
        delay: 500,
        payload: { startDate: startDate, endDate: endDate,sample_rate:sample_rate,channel_list:channel_list   },
      }
    );
    addMessageToState(botMessage);
    setReviewStep(1);
  };

  const handleComplianceReview = (reviewName) => {
    const botMessage = createChatBotMessage(
      "How much data sample rate you want?",
      {
        widget: "sampleRateSelector",
        withAvatar: true,
        delay: 500,
        payload: { reviewName: reviewName },
      }
    );
    addMessageToState(botMessage);
    setReviewStep(1);
  };

  const handleSampleRateSubmit = (selectedRates, reviewName) => {
    const botMessage = createChatBotMessage(
      "What are channels you want to include in review?",
      {
        widget: "channelsSelector",
        withAvatar: true,
        delay: 500,
        payload: { sample_rate: selectedRates, reviewName: reviewName },
      }
    );
    addMessageToState(botMessage);
    setReviewStep(2);
  };

  const handleReviewerListSubmit = (reviewers, startDate, endDate) => {
    const botMessage = createChatBotMessage(
      "What are channels you want to include in review?",
      {
        widget: "channelsSelector",
        withAvatar: true,
        delay: 500,
        payload: {
          reviewers: reviewers,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    addMessageToState(botMessage);
    setReviewStep(2);
  };

  const handleChannelsSubmit = (sample_rate, channel_list, reviewName) => {
    const botMessage = createChatBotMessage(
      "What time frame you want to consider for this review?",
      {
        widget: "timeFrameSelector",
        withAvatar: true,
        delay: 500,
        payload: {
          sample_rate: sample_rate,
          channel_list: channel_list,
          reviewName: reviewName,
        },
      }
    );
    addMessageToState(botMessage);
    setReviewStep(3);
  };

  const handleTimeFrameSelection = (sample_rate, channel_list) => {
    const botMessage = createChatBotMessage(
      "OK great, We will prepare data for you and notify you once its ready."
    );
    addMessageToState(botMessage);

    const startComplianceReviewButtonMessage = createChatBotMessage(
      "Do you want any other report ?",
      {
        widget: "startComplianceReviewButton",
        withAvatar: true,
        delay: 500,
        payload: { sample_rate: sample_rate, channel_list: channel_list },
      }
    );
    addMessageToState(startComplianceReviewButtonMessage);
    setReviewStep(0);
  };

  const addMessageToState = (botMessage) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleFromDate = (btn, sample_rate, channel_list) => {
    const fromDateMessage = createChatBotMessage("Select date range", {
      widget: "fromDateSelector",
      withAvatar: true,
      delay: 500,
      payload: { btnType: btn, sample_rate:sample_rate , channel_list:channel_list},
    });
    addMessageToState(fromDateMessage);
    setReviewStep(1);
  };

  const handleReviewersSelection = (startDate, endDate) => {
    const reviewerSelector = createChatBotMessage("Select reviewers", {
      widget: "reviewerSelector",
      withAvatar: true,
      delay: 500,
      payload: { startDate: startDate, endDate: endDate },
    });
    addMessageToState(reviewerSelector);
    setReviewStep(1);
  };

  const handleReviewersSubmit = (reviewers, startDate, endDate) => {
    const botMessage = createChatBotMessage(
      "OK great, We will prepare data for you and notify you once its ready."
    );
    addMessageToState(botMessage);
    const startComplianceReviewButtonMessage = createChatBotMessage(
      "Do you want any other report ?",
      {
        widget: "startComplianceReviewButton",
        withAvatar: true,
        delay: 500,
        payload: {
          reviewers: reviewers,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    addMessageToState(startComplianceReviewButtonMessage);
    setReviewStep(0);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleStartReview,
            handleComplianceReview,
            handleSampleRateSubmit,
            handleChannelsSubmit,
            handleTimeFrameSelection,
            handleFromDate,
            handleReviewersSelection,
            handleReviewersSubmit,
            handleReviewerListSubmit,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
