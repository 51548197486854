import React from "react";
import { Button } from "react-bootstrap";
import WebService from "../../Services/WebService";

const StartComplianceReviewButton = ({ actionProvider }) => {
  const handleClick = (btnId) => {
    WebService.addLoader(btnId);
    setTimeout(() => {
      WebService.removeLoader(btnId);
      if (btnId === "review-btn") {
        actionProvider.handleComplianceReview();
      } else if (btnId === "activity-btn") {
        localStorage.setItem("btn","activity-btn");
        actionProvider.handleFromDate("activity-btn");
      } else if (btnId === "flagging-btn") {
        localStorage.setItem("btn","flagging-btn")
        actionProvider.handleFromDate("flagging-btn");
      }
    }, 2000);
  };

  return (
    <div className="react-chatbot-kit-chat-bot-message">
      <Button
        id="review-btn"
        className="btn-brand-light mt-2 cursor-pointer"
        onClick={() => {
          localStorage.setItem("btn", "") 
          handleClick("review-btn")
        }}
      >
        Start Review
      </Button>
      <Button
        id="flagging-btn"
        className="btn-brand-light mt-2 cursor-pointer"
        onClick={() => handleClick("flagging-btn")}
      >
        Flagging Report
      </Button>
      <Button
        id="activity-btn"
        className="btn-brand-light mt-2 cursor-pointer"
        onClick={() => handleClick("activity-btn")}
      >
        Activity Report
      </Button>
    </div>
  );
};

export default StartComplianceReviewButton;









