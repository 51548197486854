import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import WebService from "../../Services/WebService";
import { useSelector } from "react-redux";
import HelperService from "../../Services/HelperService";
import TableLoader from "../Common/TableLoader/TableLoader";
import { toast } from "react-toastify";

const ChannelsSelector = (props) => {
  const { actionProvider } = props;
  const access_token = useSelector((state) => state.aT.aT);
  const t_id = useSelector((state) => state.tI.tenantId);
  const [activeChannelsList, setActiveChannelsList] = useState([]);
  const [channelSelectErrFlag, setChannelSelectErrFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    getActiveChannels();
  }, []);

  const getActiveChannels = () => {
    setLoader(true);
    let body = {};
    return WebService.getAPI({
      action: `user-integration/list`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id,
    })
      .then((res) => {
        if (res.length !== 0) {
          var arr = [];
          const modifiedIntegrationDetails = [];

          res.map((channel, index) => {
            if (
              channel.type === "INSTAGRAM" ||
              channel.type === "LINKEDIN" ||
              channel.type === "X"
            ) {
              let hasBusinessAccount = false;
              let hasPersonalAccount = false;

              if (Array.isArray(channel.list)) {
                channel?.list.forEach((item) => {
                  if (item.accountType === "BUSINESS") {
                    hasBusinessAccount = true;
                  }
                  if (item.accountType === "PERSONAL") {
                    hasPersonalAccount = true;
                  }
                });
              }

              if (hasBusinessAccount) {
                const businessIntegration = {
                  ...channel,
                  subtype: "BUSINESS",
                };
                modifiedIntegrationDetails.push(businessIntegration);
              }

              if (hasPersonalAccount) {
                const personalIntegration = {
                  ...channel,
                  subtype: "PERSONAL",
                };
                modifiedIntegrationDetails.push(personalIntegration);
              }
            } else {
              modifiedIntegrationDetails.push(channel);
            }
          });
          setActiveChannelsList(modifiedIntegrationDetails);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  }; 

  const handleSubmit = () => {
    if (selectedChannels.length === 0) {
      setChannelSelectErrFlag(true);
      return false;
    }
    WebService.addLoader("channel-submit-btn");
    if (
      localStorage.getItem("btn") === "flagging-btn" ||
      localStorage.getItem("btn") === "activity-btn"
    ) {
      submitFlaggingReport();      
    } else {
      setTimeout(() => {
        WebService.removeLoader("channel-submit-btn");
        actionProvider.handleChannelsSubmit(
          props?.payload?.sample_rate,
          selectedChannels,
          props?.payload?.reviewName
        );
      }, 2000);
    }
    
  };

  

  const submitFlaggingReport = () => {
    var uniquePlatforms = [];
    selectedChannels.forEach(function(item) {
      var baseName = item.replace(/BUSINESS|PERSONAL/g, '');  
      if (!uniquePlatforms.includes(baseName)) {
        uniquePlatforms.push(baseName);
      }
    });
    
    let body = {
      "folders": uniquePlatforms,
      "reviewers": props?.payload?.reviewers,
      "startDate": props?.payload?.startDate,
      "endDate": props?.payload?.endDate,
      "accessRights": "ALL",
      "isFlagged": true,
      "isViewed": true,
      "isEscalated": true,
      "type":"BOT"
    };
    return WebService.postAPI({
      action: `report/flagging/list/pdf`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id,
    })
      .then((res) => {
        toast.success(res);
        WebService.removeLoader("channel-submit-btn");
        actionProvider.handleTimeFrameSelection(
          props?.payload?.sample_rate,
          selectedChannels
        );
      })
      .catch((error) => {
        actionProvider.handleTimeFrameSelection(
          props?.payload?.sample_rate,
          selectedChannels
        );
        WebService.removeLoader("channel-submit-btn");
        return error;
      });
  };

  

const handleCheckboxChange = (value, checked) => {
  setChannelSelectErrFlag(false);
  if (checked) {
    setSelectedChannels([...selectedChannels, value]);
  } else {
    setSelectedChannels(selectedChannels.filter((channel) => channel !== value));
  }
};

const handleSelectAll = () => {
  setChannelSelectErrFlag(false);
  if (selectAllChecked) {
    setSelectedChannels([]);
  } else {
    const allChannels = activeChannelsList.map((channel) =>
      channel.subtype ? channel.type + channel.subtype : channel.type
    );
    setSelectedChannels(allChannels);
  }
  setSelectAllChecked(!selectAllChecked);
};

useEffect(() => {
  if (selectedChannels.length === activeChannelsList.length && activeChannelsList.length > 0) {
    setSelectAllChecked(true);
  } else {
    setSelectAllChecked(false);
  }
}, [selectedChannels, activeChannelsList]);
 

  return (   
    <div className="">
      {!loader ? (
        <div className="react-chatbot-kit-chat-bot-message">
          <div>
            <label style={{ color: 'black' }}>
              <input
                type="checkbox"
                name="channel"
                onClick={handleSelectAll}
                checked={selectAllChecked}
              />{' '}
              Select All
            </label>
          </div>
          {activeChannelsList.map((channel) => {
            const value = channel.subtype
              ? channel.type + channel.subtype
              : channel.type;

            return (
              <div key={value}>
                <label style={{ color: 'black' }}>
                  <input
                    type="checkbox"
                    name="channel"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.value, e.target.checked)
                    }
                    value={value}
                    checked={selectedChannels.includes(value)}
                  />{' '}
                  {channel.type === 'ZOOM_SMS'
                    ? 'Zoom SMS'
                    : channel.type === 'RING_CENTRAL'
                    ? 'Ring Central'
                    : channel.type === 'MY_REPCHAT'
                    ? 'MyRepChat'
                    : channel.type === 'SMS'
                    ? 'Android SMS'
                    : channel.type === 'TEAMS'
                    ? 'Teams Chat'
                    : channel.type === 'IMESSAGE'
                    ? 'iMessage'
                    : channel.type === 'ZOOM_CHAT'
                    ? 'Zoom Chat'
                    : channel.type === 'WHATSAPP'
                    ? 'WhatsApp'
                    : channel.subtype
                    ? HelperService.titleCase(channel.type) +
                      ' ' +
                      HelperService.titleCase(channel.subtype)
                    : HelperService.titleCase(channel.type)}
                </label>
                <br />
              </div>
            );
          })}
          {channelSelectErrFlag ? (
            <p className="font-10 text-danger">Please select channels</p>
          ) : (
            ''
          )}
          <Button
            id="channel-submit-btn"
            className="btn-brand-light mt-2"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      ) : (
        <TableLoader />
      )}
    </div>
  );
};

export default ChannelsSelector;









