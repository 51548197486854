import React, { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { useSelector } from "react-redux";
import NoDataFound from "../../assets/images/empty_item.svg";
import { Offcanvas } from "react-bootstrap";
import "./AuditLog.scss"
import HelperService from "../../Services/HelperService";

interface propsData {
    post_id: any;
    type: any;
    show: boolean;
    isShow: any
}
const AuditLog = (props: propsData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const [show, setShow] = useState(false);
    const [list, setList] = useState<any>([]);

    useEffect(() => {
        getLogs(1);
    }, [])

    const getLogs = (page: any) => {
        return WebService.postAPI({
            action: `audit-log?post_id=${props.post_id}&type=${props.type}&page=${page}`,
            body: null,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                setList(res.list)
                setShow(true);
            })
            .catch((error: any) => {
                return error;
            });
    };

    const getMessage = (obj: any) => {

        var message = ""
        var key = obj.action
        switch (key) {
            case "VIEWED_TRUE":
                message = "<strong>Viewed</strong> by <strong>" + obj.userName + "</strong>";
                break;
            case "FLAGGED_TRUE":
                message = "<strong>Flagged</strong> by <strong>" + obj.userName + "</strong>";
                break;
            case "ESCALATED_TRUE":
                message = "<strong> Escalated</strong> by <strong>" + obj.userName + "</strong>";
                break;
            case "VIEWED_FALSE":
                message = "<strong> Unviewed</strong> by <strong>" + obj.userName + "</strong>";
                break;
            case "FLAGGED_FALSE":
                message = "<strong> Unflagged</strong> by <strong>" + obj.userName + "</strong>";
                break;
            case "ESCALATED_FALSE":
                message = "<strong>Unescalated</strong>  by <strong>" + obj.userName + "</strong>";
                break;
            case "NOTE_UPDATED":
                message = "<strong>Note updated</strong> by <strong>" + obj.userName + "</strong>";
                break;
            case "REVIEW_LATER_TRUE":
                //message = "Post viewed by " + obj.userName;
                break;
            case "REVIEW_LATER_FALSE":
                //  message = "Post viewed by " + obj.userName;
                break;
            case "AUTOFLAGGED":
                message = "<strong>Auto flagged</strong> by <strong>AI " + "</strong>";
                break;

            case "NOTE_ADDED":
                message = "<strong>Note added</strong> by <strong>" + obj.userName + "</strong>";
                break;

            case "NOTE_REMOVED":
                message = "<strong>note removed</strong> by <strong>" + obj.userName + "</strong>";
                break;


            default:
                message = "default";
                break;
        }
        return message;

    }


    return (
        <>
            {show ? <Offcanvas
                show={show}
                onHide={() => { props.isShow(false); setShow(false) }}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Audit logs</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {list.length > 0 ?
                        list.map((ele: any, index: number) => {
                            return (
                                <div className="campaign-main-div">
                                    <div>
                                        <div className="campaign-icon">

                                        </div>
                                        <div className={index < (list.length - 1) ? "connect-line" : ""}></div>
                                    </div>
                                    <div className="campaign-div">
                                        <p className=" font-12 mb-0" dangerouslySetInnerHTML={{ __html: getMessage(ele) }}></p>
                                        <p className="text-brand mb-0 font-12 textClas">{HelperService.getFormatedDateForDetail(ele.activityDatetime)}</p>
                                    </div>
                                </div>
                            )
                        })
                        : <div className='position-relative'>
                            <div className='no-data-flound'>
                                <div className='text-center'>
                                    <img alt="" src={NoDataFound} width={110} className='mb-3' />
                                    <h3 className='text-secondary font-12'>{"No data found"}</h3>
                                </div>
                            </div>
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas> : ""}
        </>
    );
}
export default AuditLog;